<template>
  <el-dialog 
    width="800px"
    custom-class="zg-dialog"
    title="员工信息"
    :center="true"
    :lock-scroll="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible">

    <div class="custom-scroll-bar content">
      <el-form ref="memberForm"
        label-width="150px"
        label-position="right"
        :rules="rules"
        :model="user" 
        :validate-event="false"
        :disabled="!editModel">
        
        <!-- 头像 -->
        <el-form-item label="头像/微信二维码">
          <div class="head-img">
            <img :src="user.headImg || $configs.headImg">
            <div @click="uploadImage(1)"
              :class="{'head-img-none': !editModel, 'head-img-hover': editModel}" 
              class="el-icon-upload2">重新上传</div>
          </div>
          <div class="head-img">
            <img :src="user.qrCode || 'https://img0.qll-times.com/cdn/zgimg/qll-website-code.png'">
            <div @click="uploadImage(2)"
              :class="{'head-img-none': !editModel, 'head-img-hover': editModel}"
              class="el-icon-upload2">重新上传</div>
          </div>
        </el-form-item>

        <!-- 姓名 -->
        <el-form-item label="姓名" prop="name">
          <el-input v-model="user.name"></el-input>
        </el-form-item>

        <!-- 职位 -->
        <el-form-item label="职位">
          <el-input v-model="user.position"></el-input>
        </el-form-item>

        <!-- 名片展示手机号 -->
        <el-form-item label="手机号" prop="phoneNumber">
          <el-input v-model="user.phoneNumber" maxLength="11"></el-input>
        </el-form-item>

        <!-- 用户手机号 -->
        <!-- <el-form-item label="用户手机号" prop="tel">
          <el-input v-model="user.tel" maxLength="11"></el-input>
        </el-form-item> -->

        <!-- 邮箱 -->
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="user.email"></el-input>
        </el-form-item>

        <!-- 地址 -->
        <el-form-item label="地址">
          <p>{{ user.address || '--' }}</p>
        </el-form-item>
        
        <!-- 部门 -->
        <el-form-item label="部门">
          <el-tag v-if="user.teamName" type="info">{{ user.teamName }}</el-tag>
          <el-button @click="updateTeam()" type="text">设置部门</el-button>
        </el-form-item>

        <!-- 企管角色 -->
        <el-form-item label="企管角色">
          <el-select v-model="user.roles" value-key="id" multiple placeholder="请选择" style="width: 100%">
            <el-option v-for="(item, index) in rolesList"
              :key="index"
              :label="item.name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 个性标签 -->
        <el-form-item label="个性标签">
          <el-tag v-if="user.teamName" type="info">{{ user.teamName }}</el-tag>
          <p v-else>暂无标签</p>
        </el-form-item>

        <!-- 个性签名 -->
        <el-form-item label="个性签名">
          <p>{{ user.intro || '暂无签名' }}</p>
        </el-form-item>

        <!-- 照片 -->
        <el-form-item label="照片">
          <template v-if="user.configs && user.configs.photo">
            <img class="photo-img" v-for="(item, index) in user.configs.photo" :key="index" :src="item">
          </template>
          <p v-else>暂无照片</p>
        </el-form-item>
      </el-form>
    </div>

    <!-- 底部操作按钮 -->
    <span v-if="editModel" slot="footer" class="dialog-footer">
      <el-button plain @click="dialogVisible = false">取消</el-button>
      <el-button @click="updateMember()" type="primary">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import memberDialog from "@/websites/cms/js/dialog"
import {checkPhone, promisify, isObject} from "@/js/Utils"
export default {
  data(){
    return {
      dialogVisible: false,
      editModel: false, // 是否为编辑模式
      user: {}, // 成员信息
      rolesList: [], // 公司角色列表
      rules: { // 表单校验规则
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
        ],
        phoneNumber: [{validator:function(rule, value, callback){
          if(checkPhone(value) == false){
            callback(new Error("请输入正确的手机号"));
          }else{
            callback();
          }
        }, trigger: 'blur'}],
        email: [{type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur'}]
      }
    }
  },
  methods: {
    // 打开成员信息弹窗
    async openDialog(id, editModel){
      this.editModel = editModel;
      await this.getMember(id);
      await this.getRoleList();
      this.dialogVisible = true;
    },

    // 获取成员信息
    async getMember(id){
      this.dialogVisible = true;
      let res = await this.$ZGManager.getTeamMemberInfo(id);
      if(res.status == 200 && res.data){
        if(!res.data.roles) res.data.roles = [];
        if(res.data.configs) res.data.configs = JSON.parse(res.data.configs);
        this.user = res.data;
      }
    },

    // 更新成员信息
    async updateMember(){
      let getResult = await this.$refs['memberForm'].validate;
      let result = await getResult();
      if(isObject(result)) return this.$message.error("信息填写有误，请检查后重新输入");
      let res = await this.$ZGManager.updateMemberInfo(this.user.id, this.user);
      if(res.status == 200 && res.data){
        this.dialogVisible = false;
        this.$message.success("修改成功");
      }
    },

    // 获取所有角色
    async getRoleList(){
      let res = await this.$ZGManager.getRoleList();
      if(res.status == 200 && res.data){
        this.rolesList = res.data;
      }
    },

    // 更换图片
    uploadImage(type){
      memberDialog.show({
        cropWidth: 200,
        cropHeight: 200,
        fixedArray: ['1', '1'],
        selected: (item) => {
          if(type == 1){
            this.user.headImg = item.url;
          }else if(type == 2){
            this.user.qrCode = item.url;
          }
        }
      });
    },

    // 更换部门
    updateTeam(){
      memberDialog.show({
        type: 'selectTeam',
        selected: (item) => {
          this.user.teamId = item.id;
          this.user.teamName = item.label;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
}
.head-img{
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-right: 20px;
  position: relative;
  img{
    max-width: 100%;
    max-height: 100%;
  }
  &:hover .head-img-hover{
    display: inline-block;
  }
  .head-img-none{
    pointer-events: none;
    cursor: help;
    display: none;
  }
  .head-img-hover{
    width: 100%;
    height: 100%;
    line-height: 80px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    display: none;
    cursor: pointer;
  }
}
.dialog-footer{
  margin-top: 10px;
}
.photo-img{
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
}
</style>