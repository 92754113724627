<template>
  <div class="container">
    <!-- 团队架构 -->
    <div class="left">
      <el-tree class="tree" node-key="id"
        :indent="10"
        :expand-on-click-node="false"
        :default-expanded-keys="indexId"
        :data="teamsList">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span @click="getTeamMember(data)" class="zgcms width-l1"> 
            {{ data.id == teamsList[0].id ? '' : '&#xe60d;' }}
            {{ node.label }}
          </span>
          <template v-if="data.id == teamsList[0].id">
            <span class="el-icon-circle-plus-outline width-l1" @click="createTeam"></span>
          </template>
          <template v-else>
            <span style="color: #1a9afd" class="zgcms" @click="deleteTeam(data)">&#xe8c1;</span>
            <span style="color: #1a9afd" class="zgcms" @click="updateTeam(data)">&#xe8cf;</span>
          </template>
        </span>
      </el-tree>
    </div>

    <div class="right">
      <!-- 查询条件 -->
      <div class="header">
        <h2 style="float: left">当前团队：{{selectTeam.label}}</h2>
        <!-- <span style="float: right">全部组织架构可见
          <el-switch v-model="value" active-color="#1a9afd" inactive-color="#bbbbbb"></el-switch>
        </span> -->
      </div>

      <!-- 表格 -->
      <div class="header">
        <el-input class="header-container" style="width: 200px"
          placeholder="请输入姓名搜索"
          suffix-icon="el-icon-search"
          v-model="memberSearch">
        </el-input>
        <el-select class="header-container" style="width: 100px"
          v-model="memberStatus" @change="getTeamMember(selectTeam)" placeholder="请选择状态">
          <el-option
            v-for="item in memberStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button class="header-container" plain @click="openBatchDelMember()">批量删除</el-button>
        <el-button class="header-container" plain @click="openBatchUpdateMember()">批量修改部门</el-button>
        <el-button class="header-container" @click="getTeamMember(selectTeam)" type="primary">搜索/刷新</el-button>
      </div>

      <!-- 成员列表 -->
      <el-table ref="multipleTable" style="width: 100%" :fit="true"
        :data="memberList.filter(e => !memberSearch || e.name.toLowerCase().includes(memberSearch.toLowerCase()))">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column label="头像" width="70">
          <template slot-scope="scope">
            <img style="width: 40px" 
              :src="scope.row.headImg || $configs.headImg"> 
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="position" label="职位">
          <template slot-scope="scope">
            {{ scope.row.position || "无" }}
          </template>
        </el-table-column>
        <el-table-column label="部门">
          <template slot-scope="scope">
            {{ scope.row.teamName || "无" }}
          </template>
        </el-table-column>
        <el-table-column label="级别">
          <template slot-scope="scope">
            {{ scope.row.supervisor ? '团队主管' : "团队成员" }}
          </template>
        </el-table-column>
        <el-table-column label="手机">
          <template slot-scope="scope">
            {{ scope.row.phoneNumber || "无" }}
          </template>
        </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            <!-- <span v-for="(item, index) in scope.row.roles" :key="index">{{item.name}}</span> -->
            <template v-if="scope.row.roles">
              <template v-for="(item, index) in scope.row.roles">
                <el-tag :key="index" class="roles-tag">{{ item.name }}</el-tag>
              </template>
            </template>
            <template v-else>无</template>
          </template>
        </el-table-column>
        <el-table-column label="成员状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="updateMemberStatus(scope.row)"
              :active-value="1" :inactive-value="0"
              active-color="#1a9afd" inactive-color="#999999"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="scope">
            <el-button @click="$refs.memberDesc.openDialog(scope.row.id, true)" type="text">编辑</el-button>
            <el-button style="color: rgba(0, 0, 0, 0.1)" type="text">|</el-button>
            <el-button @click="$refs.memberDesc.openDialog(scope.row.id, false)" type="text">查看详情</el-button>
            <el-button style="color: rgba(0, 0, 0, 0.1)" type="text">|</el-button>
            <el-button @click="openDialogSetRole(scope.row.id)" type="text">设置角色</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <!-- 员工详情 -->
      <member-desc ref="memberDesc"></member-desc>
      <selectteam-dialog></selectteam-dialog>
      <el-dialog title="设置角色账号" :visible.sync="openDialogSetRoleVisible" width="500px">
        <el-form :model="form" :rulse="setAccountRules">
          <el-form-item label="设置账号" label-width="80px">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="设置密码" label-width="80px">
            <el-input v-model="form.password" autocomplete="off"></el-input>
          </el-form-item>
            <el-form-item label="确认密码" label-width="80px">
            <el-input v-model="form.againPassword" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="openDialogSetRoleVisible = false">取 消</el-button>
          <el-button type="primary" @click="openDialogSetRoleVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </div> 
  </div>
</template>

<script>
import { checkStringLength } from '@/js/Utils'
import MemberDesc from '../../components/MemberDesc'
import selectTeam from '@/websites/cms/js/dialog'
import selectTeamDialog from '@/websites/cms/components/dialog/SelectTeam'
const VueQr = () => import('vue-qr');
export default {
  components: { 
    VueQr,
    'member-desc': MemberDesc,
    'selectteam-dialog': selectTeamDialog
  },
  data(){
    return {
      companyId: '', // 公司id
      teamsName: '', // 团队名称搜索框
      teamsList: [], // 团队列表
      indexId: [], // 当前展开的团队ID
      selectTeam: {
        id: '', // 当前选中的团队ID
        label: '', // 当前选中的团队名称
      },
      memberList: [], // 当前团队成员列表
      selectMemberList: [], // 当前选中的成员列表
      memberSearch: '', // 成员搜索
      value: true,
      memberStatus: '0+',
      memberStatusOptions: [
        { value: '0+', label: '全部' },
        { value: '1',  label: '已激活' },
        { value: '0',  label: '未激活' }
      ],
      openDialogSetRoleVisible:false,//设置角色账号弹出框
      form:{//设置角色表单
        username:'',
        password:'',
        againPassword:''
      },
      setAccountRules: {//表单校验规则
        username:[],
        password:[]
      }
    }
  },
  mounted(){
    this.getCompany();
  },
  computed: {
    // qrCodeText(){
    //   let tid = this.selectTeam.id;
    //   if(tid == 0) tid = "";
    //   return `https://gt.qll-times.com/api/tk/co/join/stmTRn/${this.companyId}?tid=${tid}`
    // }
  },
  methods: {
    //设置角色
    openDialogSetRole(id) {
      console.log(id);
      this.openDialogSetRoleVisible = true
    },
    // 获取公司信息
    async getCompany(){
      let res = await this.$ZGManager.getMyCompany();
      if(res.status == 200 && res.data){
        this.companyId = res.data.id;
        this.teamsList.push({
          id: 0,
          label: res.data.name,
          children: []
        });
        this.indexId.push(0);
        this.getMyTeams();
      }
    },

    // 获取我的团队列表
    async getMyTeams(){
      this.teamsList[0].children = [];
      let res = await this.$ZGManager.getMyTeams();
      if(res.status == 200){
        res.data.map((e) => {
          this.teamsList[0].children.push({
            id: e.id,
            label: e.name,
          })
        });
        this.getTeamMember(this.teamsList[0]);
      }
    },

    // 查询团队成员列表
    async getTeamMember(item){
      this.selectTeam.id = item.id;
      this.selectTeam.label = item.label;
      let res = await this.$ZGManager.getTeamMember(item.id, this.memberStatus);
      if(res.status == 200){
        this.memberList = res.data;
        console.log(this.memberList,'memberListmemberListmemberList');
      }
    },

    // 新增团队
    async createTeam(){
      let dialog = await this.$prompt('请输入团队名称', '提示', {
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      if(dialog.action != 'confirm') return;
      if(!checkStringLength(dialog.value, 4, 20)) return this.$message.error("团队名称应在4-20个字符之间（一个汉字为2个字符）");
      let res = await this.$ZGManager.createTeam(dialog.value);
      if(res.status == 200 && res.data){
        this.getMyTeams();
        this.$message.success(`新增了团队-${dialog.value}`);
      }
    },

    // 修改团队
    async updateTeam(item){
      let dialog = await this.$prompt('请输入团队名称', '提示', {
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        inputValue: item.label,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      if(dialog.action != 'confirm') return;
      if(!checkStringLength(dialog.value, 4, 20)) return this.$message.error("团队名称应在4-20个字符之间（一个汉字为2个字符）");
      let res = await this.$ZGManager.updateTeam(item.id, {name: dialog.value});
      if(res.status == 200 && res.data){
        this.getMyTeams();
        this.$message.success("修改成功");
      }
    },

    // 删除团队
    async deleteTeam(item){
      let dialog = await this.$confirm(`您确定要删除-${item.label}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      });
      if(dialog != 'confirm') return;
      let res = await this.$ZGManager.deleteTeam(item.id);
      if(res.status == 200 && res.data){
        this.getMyTeams();
        this.$message.success("删除成功");
      }
    },

    // 打开批量删除成员弹窗
    async openBatchDelMember(){
      let data = this.$refs.multipleTable.selection;
      if(data.length < 1) return this.$message.warning('请选择人员进行删除操作');
      let dialog = await this.$confirm(`
        您确定要删除“${data[0].name}”等${data.length}人吗？将不可恢复。`, 
        '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      this.bacthUpdateMember(data, 1, {status: -1});
    },

    // 打开批量修改成员部门弹窗
    async openBatchUpdateMember(){
      let data = this.$refs.multipleTable.selection;
      if(data.length < 1) return this.$message.warning('请选择人员进行操作');
      selectTeam.show({
        type: 'selectTeam',
        selected: (item) =>{     
          this.bacthUpdateMember(data, 1, {teamId: item.id});
        }
      });
    },

    // 批量更新成员
    async bacthUpdateMember(data, index, item){
      console.log(data, index, item);
      for(let key of data){
        await this.$ZGManager.updateMemberInfo(data[index - 1].id, item);
      }
      this.$message.success("修改成功");
      this.getTeamMember(this.selectTeam || this.this.teamsList[0]);
    },

    // 修改成员状态
    async updateMemberStatus(item){
      let status = 0;
      item.status ? status = 1 : status = 0;
      let res = await this.$ZGManager.updateMemberInfo(item.id, {status: status});
      if(res.status == 200){
        this.$message.success("修改成功");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left{
  width: 240px;
  height: calc(100% - 20px);
  background-color: #fff;
  padding: 10px 0;
  float: left;
  .tree{
    margin-top: 10px;
  }
  .zgcms{
    font-size: 14px;
  }
  .width-l1{
    max-width: 150px;
    display: inline-block;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    vertical-align: text-bottom;
  }
}
.right{
  width: calc(100% - 270px);
  height: calc(100% - 20px);
  background-color: #fff;
  padding: 10px;
  float: right;
  overflow-y: auto;
  .header{
    overflow: hidden;
    margin-bottom: 15px;
    ::v-deep .el-button{
      margin-left: 0;
    }
  }
  .header-container{
    margin-right: 10px;
  }
}
.roles-tag{
  margin-right: 5px;
  margin-bottom: 5px;
}
.el-icon-circle-plus-outline{
  color: #1a9afd; 
  font-size: 20px; 
  vertical-align: bottom;
}
</style>